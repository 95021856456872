import { Table, Tooltip, Modal, Badge } from "antd";
import React, { useEffect, useState } from "react";
import {
  FEEDBACK_STATUS,
  NotificationType,
  openNotificationWithIcon,
} from "../../../../utils";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
  EditFilled,
  SaveOutlined,
} from "@ant-design/icons";
import { documentService } from "../../../../api";
import "./split-summary.scss";
import { SplitDataItem, SplitDataType } from "../../constants";
import { PDFDocument } from "pdf-lib";
import { CommentsModal } from "../../../modals";
import { commentService } from "../../../../api/comment";
import { useParams } from "react-router-dom";
import { useAuthUser } from "../../../../hooks";
import { PageComment } from "../../../../interfaces/document";
import { ColumnsType } from "antd/es/table";


type Props = {
  setSelectedCategory: React.Dispatch<any>;
  pdfUrl: string;
  splitData: any;
  getSections: () => Promise<void>;
};

interface DataType {
  key: number;
  section: string;
  page_range: string;
  category: string;
  sectionId: string;
}

const getColumns = (): ColumnsType<SplitDataItem> => [
  {
      title: "Section",
      dataIndex: "section",
      key: "section",
      width: "30%",
      responsive: ["sm"], 
  },
  {
      title: "Placeholder Id",
      dataIndex: "placeholderId",
      key: "placeholderId",
      responsive: ["sm"],
  },
  {
    title: "Placeholder Name",
    dataIndex: "placeholderName",
    key: "placeholderName",
    responsive: ["sm"]
  },
  {
      title: "Ph Document Name",
      dataIndex: "phDocumentName",
      key: "phDocumentName",
      responsive: ["sm"],
  },
  {
      title: "Page Range",
      dataIndex: "pageRange",
      key: "pageRange",
      responsive: ["md"],
  },
  {
      title: "Confidence Score",
      dataIndex: "confidenceScore",
      key: "confidenceScore",
      render: (_: any, record: any) => (
          <>{record.confidenceScore?.toString().slice(0, 4)}</>
      ),
      responsive: ["md"],
  },
];

export const SplitSummary = ({
  setSelectedCategory,
  pdfUrl,
  splitData,
  getSections,
}: Props) => {
  const { documentUuid } = useParams();
  const [data, setData] = useState([] as SplitDataItem[]);
  const [feedbackStatus, setFeedbackStatus] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sectionDetails, setSectionDetails] = useState({} as SplitDataItem);
  const [comments, setComments] = useState([] as PageComment[]);
  const [isLoading, setIsLoading] = useState(false);
  const [editPageRangeMap, setEditPageRangeMap] = useState({} as any);
  const { firstName, lastName } = useAuthUser();
  
  const handleEditPageRange = (record: any) => {
    setEditPageRangeMap((prevEditPageRangeMap: any) => ({
      ...prevEditPageRangeMap,
      [record.key]: !prevEditPageRangeMap[record.key],
    }));
  };

  const handleModalOpen = async (record: SplitDataItem) => {
    setSectionDetails(record);
    setIsModalOpen(true);
    fetchCommentsByPage(record, "section");
  };

  const fetchCommentsByPage = async (record: SplitDataItem, type: string) => {
    setIsLoading(true);
    try {
      if (documentUuid) {
        const sectionWiseComments = await commentService.getAll({
          documentUuid,
          sectionId: record.sectionId,
          type,
        });
        setComments(sectionWiseComments);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    
    const tableData: any = splitData.map((item: any, index: Number) => ({
      key: index,
      ...item,
      }));
    setData(tableData);
  }, [splitData]);

  const downloadSelectedPages = async (record: SplitDataItem) => {
    const [start, end] = record.pageRange.split(" - ").map(Number);

    try {
      const pdfBytes = await fetch(pdfUrl).then((res) => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(pdfBytes);

      const selectedPdfDoc = await PDFDocument.create();

      for (let i = start; i <= end; i++) {
        const [copiedPage] = await selectedPdfDoc.copyPages(pdfDoc, [i - 1]);
        selectedPdfDoc.addPage(copiedPage);
      }

      const selectedPdfBytes = await selectedPdfDoc.save();

      const blob = new Blob([selectedPdfBytes], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${record.category}_${record.section}_${start}-${end}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading pages:", error);
      openNotificationWithIcon("", "Error downloading pages", "error");
    }
  };

  const handleSubmitFeedback = async (newStatus: string, record: any) => {
    if (
      record.feedbackStatus === FEEDBACK_STATUS.AGREE &&
      newStatus === FEEDBACK_STATUS.DISAGREE
    ) {
      return;
    }

    if (
      record.feedbackStatus === FEEDBACK_STATUS.DISAGREE &&
      newStatus === FEEDBACK_STATUS.AGREE
    ) {
      return;
    }

    if (newStatus === record.feedbackStatus) {
      newStatus = FEEDBACK_STATUS.NOT_REVIEWED;
    }

    try {
      await documentService.updateStatus(
        record.sectionId,
        newStatus === FEEDBACK_STATUS.NOT_REVIEWED ? "" : newStatus
      );

      const updatedData = [...data];
      const index = updatedData.findIndex(
        (item: any) => item.sectionId === record.sectionId
      );

      if (index !== -1) {
        updatedData[index] = {
          ...updatedData[index],
          feedbackStatus: newStatus,
        };
        setData(updatedData);
      }

      let notificationMessage = "";
      let notificationType: NotificationType = "info";

      switch (newStatus) {
        case FEEDBACK_STATUS.DISAGREE:
          notificationMessage = "Disagreed";
          notificationType = "error";
          break;
        case FEEDBACK_STATUS.AGREE:
          notificationMessage = "Agreed";
          notificationType = "success";
          break;
        default:
          notificationMessage = "Feedback Reset";
          break;
      }

      openNotificationWithIcon("", notificationMessage, notificationType);
    } catch (error) {
      openNotificationWithIcon("", "Error updating the status", "error");
    }
  };

  const handlePageRangeChange = (
    newStartPage: any,
    newEndPage: any,
    record: any
  ) => {
    const updatedData = data.map((row) =>
      row.key === record.key
        ? { ...row, startPage: newStartPage, endPage: newEndPage }
        : row
    );
    setData(updatedData);
  };

  const handleSavePageRange = async (record: any) => {
    try {
      const res = await commentService.savePageRange({
        id: record.sectionId,
        pageStart: record.startPage,
        pageEnd: record.endPage,
      });
      {
        documentUuid &&
          commentService.save({
            comment: `The page range has been updated from ${res.oldStartPage}-${res.oldEndPage} to ${res.newStartPage}-${res.newEndPage}`,
            username: `${firstName} ${lastName}`.trim(),
            sectionId: record.sectionId,
            documentUuid: documentUuid,
            type: "section",
          });
      }
      openNotificationWithIcon(
        "",
        "Page Range updated successfully",
        "success"
      );
      handleEditPageRange(record);
      getSections();
    } catch (error) {
      console.log(error);
    }
  };



  const handleRowClick = (record: SplitDataItem) => {
    setSelectedCategory(record);
  };

  return (
    <div className="table-container">
      <Table
        columns={getColumns()}
        dataSource={data}
        pagination={false}
        rowKey={(record) => record.key}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
        scroll={{ x: true }} 
        tableLayout="auto" 
      />
        {isModalOpen && documentUuid && (
          <CommentsModal
            visibility={isModalOpen}
            handleClose={() => setIsModalOpen(false)}
            sectionDetails={sectionDetails}
            comments={comments}
            fetchComments={fetchCommentsByPage}
            documentUuid={documentUuid}
            isLoading={isLoading}
            type={"section"}
            getSections={getSections}
          />
        )}
    </div>
  );
};
