import React, { useEffect, useState } from "react";
import { DatePicker, Loading } from "../../components";
import moment, { Moment } from "moment";
import DonutChart from "./graphs/DonutChart";
import { ProcessingTrend } from "./graphs/ProcessingTrend";
import ApiCallStatistics from "./graphs/APICallStatistics";
import { APICallTrend } from "./graphs/APICallTrend";
import "./graphs/graphs.scss";
import { RootState, setSelectedDateRange, setSelectedMetros } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { ChartStatsData } from "../../interfaces/document";
// import { RangeValue } from "rc-picker/lib";
import { documentService } from "../../api";
import { MetroFilter } from "../../components/MetroFilters";
import { useFetchDocuments } from "../../hooks/useFetchDocuments";
import { Spin } from "antd";


interface DocStatsType {
  totalDocsWithData: number;
  totalFields: number;
  totalExtractedFields: number;
  totalMissingFields: number;
  totalSrfPageFound: number;
  totalSuccess: number;
  totalFailed: number;
  totalInProgress: number;
  dateWiseExtractionData: { date: string; processed: number }[];
}

type TokenData = {
  date: string;
  token_count: {
    service_name: string;
    moduleName: string;
    token_count: number;
  }[];
}[];

interface DailyPageStats {
  totalPages: number;
  totalPlaceholders: number;
  totalDocuments: number;
  averagePages: number;
  averagePlaceholders: number;
}

interface PageStatusResponse {
  data: Record<string, DailyPageStats>;
}


interface ProcessingTrendProps {
  data: { date: string; processed: number }[];
}

export const SummaryDataAndGraphs = () => {
  const { RangePicker } = DatePicker;
  const dispatch = useDispatch();
  const [docStats, setDocStats] = useState<ChartStatsData[]>([]);
  const [chartData, setChartData] = useState({
    READY: 0,
    FAILED: 0,
    PROCESSING: 0,
    QUEUED: 0,
    DOWNLOADED: 0,
  });
  const [pageCount, setPageCount] = useState<any>(null);
  const [extractionDataCount, setExtractionDataCount] =
    useState<ProcessingTrendProps | null>(null);
  const [isLoadingChartStats, setIsLoadingChartStats] = useState(false);
  const [isLoadingApiStats, setIsLoadingApiStats] = useState(false);
  const defaultFromDate = moment().startOf('month');
  const defaultToDate = moment();
  const [totalDocuments, setTotalDocuments] = useState(0);

  const { fromDate: selectedFromDate, toDate: selectedToDate, selectedMetros } = useSelector(
    (state: RootState) => ({
      fromDate: state.document.selectedDateRange.fromDate,
      toDate: state.document.selectedDateRange.toDate,
      selectedMetros: state.document.selectedMetros,
    })
  );

  const onRangeChange = (dates: any, dateStrings: string[]) => {
    if (dates) {
      dispatch(
        setSelectedDateRange({
          fromDate: dates[0]?.toString() ?? null,
          toDate: dates[1]?.toString() ?? null,
        })
      );
    } else {
      dispatch(
        setSelectedDateRange({
          fromDate: null,
          toDate: null,
        })
      );
    }
  };

  const onMetroFilterChange = (metros: string[]) => {
    dispatch(setSelectedMetros(metros));
  };

  const transformPageData = (pageData: PageStatusResponse) => {
    const transformedData = Object.entries(pageData).map(([date, stats]) => ({
      date,
      pageCount: [
        {
          service_name: "Pages",
          pageCount: stats.totalPages.toString(),
          token_count: stats.totalPages,
        },
        {
          service_name: "Placeholders",
          pageCount: stats.totalPlaceholders.toString(),
          token_count: stats.totalPlaceholders,
        },
        {
          service_name: "Average Pages",
          pageCount: parseFloat(stats.averagePages.toFixed(1)),
          token_count: parseFloat(stats.averagePages.toFixed(1)),
        },
        {
          service_name: "Average Placeholders",
          pageCount: parseFloat(stats.averagePlaceholders.toFixed(1)),
          token_count: parseFloat(stats.averagePlaceholders.toFixed(1)),
        },
      ],
    }));

    return transformedData;
  };
  // Mock API Data fetching for token and chart stats
  const fetchApiStats = async () => {
    setIsLoadingApiStats(true);
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const fromDate = moment(selectedFromDate).format('YYYY-MM-DD HH:mm:ss');
      const toDate = moment(selectedToDate).format('YYYY-MM-DD HH:mm:ss');

    const { data } = await documentService.getPageStatus(fromDate, toDate, timezone, selectedMetros);
    const totalDocuments = data.totalDocuments;
    const transformedData = transformPageData(data.data);
    setTotalDocuments(totalDocuments)
    setPageCount(transformedData);
    setIsLoadingApiStats(false);
  };

  const fetchChartStats = async () => {
    setIsLoadingChartStats(true);
    try {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const fromDate = moment(selectedFromDate).format('YYYY-MM-DD HH:mm:ss');
      const toDate = moment(selectedToDate).format('YYYY-MM-DD HH:mm:ss');;
    
      const { data } = await documentService.getDocumentStats(fromDate, toDate, timezone, selectedMetros);
      
      setDocStats(data);

      const mockChartStats = data.reduce((acc:any, item:any) => {
        const status = item.status ? item.status.toUpperCase() : "FAILED";
            acc[status] = (acc[status] || 0) + item.processed;
        return acc;
    }, {});
    
    setChartData(mockChartStats);
    
    } catch (error) {}
    setIsLoadingChartStats(false);
  };

  useEffect(() => {
    if(metros.length == 0){
      getAllMetros();
      fetchApiStats();
      fetchChartStats();
    }
    fetchApiStats();
    fetchChartStats();
  }, [selectedFromDate, selectedToDate, selectedMetros]);

  const { metros, getAllMetros } = useFetchDocuments()

  const renderDatePicker = () => (
    <div className="service-datePicker">
      <p className="service-datePicker-title">Select Date Range:</p>
      <RangePicker
        showTime={{ 
          use12Hours: true, 
          format: 'h:mm A'  
        }}
        format="YYYY-MM-DD hh:mm A" 
         defaultValue={[
          moment().subtract(1, 'days').startOf('day'), 
          moment().endOf('day')
        ]}
        onChange={onRangeChange}
        className="range-picker"
        allowClear={false}
        disabledDate={(current) =>
          current && current.valueOf() > moment().endOf("day").valueOf()
        }
      />
    </div>
  );


  const renderDonutChart = () => (
    <div className={`summary-container other-copilots`}>
      <Spin spinning={isLoadingChartStats}>
        <div className="donut-container">
          <h3 className="summary-title">Documents Processed</h3>
          <p>Total documents processed with status success, failed</p>
          <DonutChart
          donutData={[
            { type: "Success", value: chartData.READY || 0 },
            { type: "Failed", value: chartData.FAILED || 0 },
            { type: "Processing", value: chartData.PROCESSING || 0 },
            { type: "Queue", value: chartData.QUEUED || 0 },
            ...(chartData.DOWNLOADED > 0 ? [{ type: "Downloaded" as const, value: chartData.DOWNLOADED }] : [])
          ]}
        />
        </div>
      </Spin>
    </div>
  );
  

  const renderApiCallStatistics = () => (
    <div className={`summary-container other-copilots`}>
      <Spin  spinning={isLoadingApiStats}>
       <ApiCallStatistics pageCount={pageCount} totalDocuments={totalDocuments}/>
      </Spin>
    </div>
  );

  const renderProcessingTrend = () => (
    <div className={`summary-container other-copilots`}>
      <div className="processing-trend">
        <ProcessingTrend
          data={docStats}
          fromDate={moment(selectedFromDate)}
          toDate={moment(selectedToDate)}
        />
      </div>
    </div>
  );

  const renderApiCallTrend = () => (
    <div className={`summary-container other-copilots`}>
      <Spin spinning={isLoadingApiStats}>
        <APICallTrend data={{ tokenData: pageCount }} />
      </Spin>
    </div>
  );

  const renderLoading = (message: string) => (
    <div style={{ height: "200px", width: "50%" }}>
      <Loading tip={message} />
    </div>
  );

  const renderMetroFilter = () => (
    <div className="service-metro" >
      <p className="service-metro-title" >Metro Filter:</p>
      <MetroFilter
        metros={metros}
        onApply={onMetroFilterChange}
      />
    </div>
  );

  return (
    <div className="wrapper">
      <div className="service-status">
        {/* <h4 className="service-status-title">Insights</h4> */}
        <div className="service-horizontal"></div>
        {renderDatePicker()}
        {renderMetroFilter()}
      </div>

      <div
        style={{ display: "flex", gap: "20px", justifyContent: "space-evenly" }}
      >
        {isLoadingChartStats
          ? renderLoading("Loading documents stats...")
          : docStats && (
            <div className="flex flex-column w-full gp">
              <div className={`summary-container row-layout`}>
                {renderDonutChart()}
                {renderProcessingTrend()}
              </div>
              <div className={`summary-container row-layout`}>
                {renderApiCallStatistics()}
                {renderApiCallTrend()}
              </div>

              {/* <div className={`summary-container row-layout`}>
                {renderApiCallStatistics()}
              </div> */}
            </div>
            )}
      </div>
    </div>
  );
};
