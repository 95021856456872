import React, { useEffect, useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import { ChartOptions } from "chart.js";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
ChartJS.register(
  TimeScale,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);
import { STATUS_CODE, openNotificationWithIcon } from "../../../utils";
import { NotAccessibleFallback } from "../../../components";
import moment from "moment";
import { Select } from "antd";
const { Option } = Select;

interface APICallTrendProps {
  data: {
    tokenData: {
      date: string;
      pageCount: {
        service_name: string;
        moduleName: string;
        pageCount: number;
      }[];
    }[];
  };
}

interface TokenUsageData {
  pages: { date: string; value: number }[];
  placeholders: { date: string; value: number }[];
  averagePages: { date: string; value: number }[];
  averagePlaceholders: { date: string; value: number }[];
}

const processAPIData = (data: APICallTrendProps["data"], granularity: string) => {
  if (!data.tokenData || data.tokenData.length === 0) {
    return { pages: [], documents: [], averagePages: [], placeholders: [], averagePlaceholders: [] };
  }

  const result: {
    pages: Map<string, number>;
    placeholders: Map<string, number>;
    averagePages: Map<string, number>;
    averagePlaceholders: Map<string, number>;

  } = {
    pages: new Map(),
    placeholders: new Map(),
    averagePages: new Map(),
    averagePlaceholders: new Map(),
  };

  data.tokenData.forEach(({ date, pageCount }) => {
    let formattedDate: string;
    if (granularity === "weeks") {
      formattedDate = moment(date).format("YYYY-WW");
    } else if (granularity === "months") {
      formattedDate = moment(date).format("YYYY-MM");
    } else if (granularity === "years") {
      formattedDate = moment(date).format("YYYY");
    } else {
      formattedDate = moment(date).format("YYYY-MM-DD");
    }

    pageCount.forEach(({ service_name, pageCount }) => {
      if (service_name === "Pages") {
        result.pages.set(formattedDate, (result.pages.get(formattedDate) || 0) + pageCount);
      } else if (service_name === "Placeholders") {
        result.placeholders.set(formattedDate, (result.placeholders.get(formattedDate) || 0) + pageCount);
      } else if (service_name === "Average Pages") {
        result.averagePages.set(formattedDate, (result.averagePages.get(formattedDate) || 0) + pageCount);
      } else if (service_name === "Average Placeholders") {
        result.averagePlaceholders.set(formattedDate, (result.averagePlaceholders.get(formattedDate) || 0) + pageCount);
      }
    });
  });

  // Convert Map to array of objects
  return {
    pages: Array.from(result.pages, ([date, value]) => ({ date, value })),
    placeholders: Array.from(result.placeholders, ([date, value]) => ({ date, value })),
    averagePages: Array.from(result.averagePages, ([date, value]) => ({ date, value })),
    averagePlaceholders: Array.from(result.averagePlaceholders, ([date, value]) => ({ date, value })),
  };
};



export const APICallTrend = ({ data }: APICallTrendProps) => {
  const [loading, setLoading] = useState(false);
  const [tokenUsageData, setTokenUsageData] = useState<any>({
    pages: [],
    placeholders: [],
    averagePages: [],
    averagePlaceholders: [],
  });
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [timeGranularity, setTimeGranularity] = useState("days"); // Default granularity

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const processedData = processAPIData(data, timeGranularity);
        setTokenUsageData(processedData);
      } catch (err: any) {
        if (err.response && err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon(
            "",
            err.response?.data?.message || "Error fetching data",
            "error"
          );
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [data, timeGranularity]);

  const chartData = useMemo(() => {    
    return {
      labels: tokenUsageData.pages.map((item: any) => item.date),
      datasets: [
        {
          label: "Pages",
          data: tokenUsageData.pages.map((item: any) => item.value),
          fill: false,
          borderColor: "#DA7C7C",
          pointBackgroundColor: "#DA7C7C",
          pointBorderColor: "#DA7C7C",
          tension: 0.4,
        },
        {
          label: "Placeholders",
          data: tokenUsageData.placeholders.map((item: any) => item.value),
          fill: false,
          borderColor: "#7280CD",
          pointBackgroundColor: "#7280CD",
          pointBorderColor: "#7280CD",
          tension: 0.4,
        },
        {
          label: "Average Pages",
          data: tokenUsageData.averagePages.map((item : any) => item.value),
          fill: false,
          borderColor: "#5EADA6",
          pointBackgroundColor: "#5EADA6",
          pointBorderColor: "#5EADA6",
          tension: 0.4,
        },
        {
          label: "Average Placeholders",
          data: tokenUsageData.averagePlaceholders.map((item: any) => item.value),
          fill: false,
          borderColor: "#E2B039",
          pointBackgroundColor: "#E2B039",
          pointBorderColor: "#E2B039",
          tension: 0.4,
        },
      ],
    };
  }, [tokenUsageData]);

  const options = useMemo(() => {
    return {
      scales: {
        x: {
          type: "category", // Assuming time scale for x-axis
          stacked: false,
          grid: {
            drawOnChartArea: false
          }
        },
        y: {
          stacked: false,
          beginAtZero: true,
        },
      },
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          position: "bottom",
        },
      },
    } as ChartOptions<"line">;
  }, []);

  const onTimeGranularityChange = (granularity: string) => {
    setTimeGranularity(granularity);
  };

  if (!isAuthorized) {
    return <NotAccessibleFallback />;
  }

  return (
    <div className="statistics">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <h4 className="summary-title">Pages and Placeholders Trends</h4>
          <p>Total number of Pages and placeholders processed by days, week & months</p>
        </div>
        <div style={{ paddingBottom: "20px" }}>
          <Select
            defaultValue={timeGranularity}
            style={{ width: 100, height: 40 }}
            onChange={onTimeGranularityChange}
          >
            <Option value="days">Days</Option>
            <Option value="weeks">Weeks</Option>
            <Option value="months">Months</Option>
            <Option value="years">Years</Option>
          </Select>
        </div>
      </div>
      <div className="height">
        <Line data={chartData} options={options} />
      </div>
    </div>
  );
};
