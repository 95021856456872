import React, { useMemo } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Legend, Tooltip } from "chart.js";

ChartJS.register(ArcElement, Legend, Tooltip);

interface DonutChartProps {
  donutData: {
    type: "Success" | "Failed" | "Processing" | "Queue" | "Downloaded";
    value: number;
  }[];
}

const DonutChart = ({ donutData }: DonutChartProps) => {
  const colors = {
    Success: "#5CD593",
    Failed: "#DD5757",
    Processing: "#3CC2E7",
    Queue: "#FFC107",
    Downloaded: "#d7d7d7",
  };

  const chartData = {
    labels: donutData.map((item) => item.type),
    datasets: [
      {
        data: donutData.map((item) => item.value),
        backgroundColor: donutData.map((item) => colors[item.type]),
      },
    ],
  };

  const options = useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: true,
      onClick: () => {}, // Disable all click events on the chart
      events: [], // Disable all events (click, hover, etc.)
      plugins: {
        legend: {
          position: "right" as const,
          onClick: () => {}, // Disable legend click interactions
          labels: {
            usePointStyle: true,
            onClick: () => {}, // Disable click on legend items
            generateLabels: (chart: any) => {
              const labelData = chart?.data?.datasets[0]?.data;
              const labels = chart?.data?.labels;
              const legendItems: {
                text: string;
                fillStyle: string;
                hidden: boolean;
                index: number;
                datasetIndex: number;
              }[] = [];
              if (labelData && labels) {
                labels.forEach((label: "Success" | "Failed" | "Processing", index: number) => {
                  legendItems.push({
                    text: `${label} ${labelData[index]}`,
                    fillStyle: colors[label],
                    hidden: false,
                    index: index,
                    datasetIndex: 0,
                  });
                });
              }
              return legendItems;
            },
          },
        },
        tooltip: {
          enabled: true, // Enable tooltips (optional, can be disabled if required)
        },
      },
    };
  }, []);

  const textCenter = {
    id: "textCenter",
    afterDraw(chart: any) {
      const { ctx, chartArea } = chart;
      const centerX = (chartArea.left + chartArea.right) / 2;
      const centerY = (chartArea.top + chartArea.bottom) / 2;

      ctx.save();
      ctx.font = "bold 30px sans-serif";
      ctx.textAlign = "center";
      ctx.fillStyle = "#5C5E64";
      ctx.textBaseline = "middle";
      ctx.fillText(
        donutData.reduce((total, data) => total + data.value, 0),
        centerX,
        centerY
      );
      ctx.restore();
    },
  };

  return <Doughnut data={chartData} options={options as any} plugins={[textCenter]} />;
};

export default DonutChart;
