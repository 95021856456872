import { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, setPageNumberForDocument } from "../store";

const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

export const usePagination = () => {
  const dispatch = useDispatch();
  const pageNumberFromStore = useSelector((state: RootState) => state.document.pageNumber);
  
  const [page, setPage] = useState(parseInt(pageNumberFromStore) || 1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_OPTIONS[1]);

  // Sync local state with Redux store
  useEffect(() => {
    setPage(parseInt(pageNumberFromStore) || 1);
  }, [pageNumberFromStore]);

  const onChange = useCallback(
    (updatedPage: number, updatedPageSize: number) => {
      if (updatedPage !== page) {
        setPage(updatedPage);
        dispatch(setPageNumberForDocument(updatedPage.toString()));
      }
      
      if (updatedPageSize !== pageSize) {
        setPageSize(updatedPageSize);
      }
    },
    [page, pageSize, dispatch]
  );

  return { 
    page, 
    pageSize, 
    pageSizeOptions: PAGE_SIZE_OPTIONS, 
    onPaginationChange: onChange 
  };
};