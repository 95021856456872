import React from "react";
import ReactJson from "react-json-view";
import './json-content.scss';

interface Props {
  pageInfo: any;
}

export const EvidenceContentJSON = ({ pageInfo }: Props) => {
  return (
    <div className="e-text json-container">
      {/* <h2>{pageInfo.heading}</h2> */}
      <div className="e-paragraph">
        <ReactJson src={JSON.parse(JSON.stringify(pageInfo))} enableClipboard />
      </div>
    </div>
  );
};


export const IncomingContentJSON = ({ pageInfo }: Props) => {

  return (
    <div className="e-text json-container">
      <div className="e-paragraph">
        <ReactJson src={JSON.parse(pageInfo?.incomingMessage )} enableClipboard />
      </div>
    </div>
  );
};