import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from 'moment';
import { set } from "react-hook-form";

interface IModal {
  [name: string]: boolean;
}

interface UiSlice {
  modal: IModal;
  isDeleteConfirmed: boolean;
}
export const uiInitialState: UiSlice = {
  modal: {} as IModal,
  isDeleteConfirmed: false,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState: uiInitialState,
  reducers: {
    openModal: (state: UiSlice, { payload }: PayloadAction<string>) => {
      state.modal[payload] = true;
    },
    closeModal: (state: UiSlice, { payload }: PayloadAction<string>) => {
      state.modal[payload] = false;
    },
    updateDeleteConfirmation: (state: UiSlice, { payload }: PayloadAction<boolean>) => {
      state.isDeleteConfirmed = payload;
    },
  },
});

export const { openModal, closeModal, updateDeleteConfirmation } = uiSlice.actions;

export default uiSlice.reducer;
