import { isEmptyObject } from "./objectOperations";

export const filteredArray = (
  array: Array<Record<string, unknown>>,
  valueToRemove: unknown,
  filterByKey: string
) => array.filter((item) => item[filterByKey] !== valueToRemove);

export const getExistingSearchParams = (searchParams: URLSearchParams) => {
  const params = [];
  for (const entry of searchParams.entries()) {
    params.push(entry);
  }
  const res: { [key: string]: string } = {};
  params.map(([key, value]) => {
    res[key] = value;
  });
  return res;
};

export const hexToRGBColor = (hex: string, alpha = 1) => {
  const match = hex.match(/\w\w/g);
  if (match && match.length) {
    const [r, g, b] = match.map((x) => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  }
  return `rgba(255,255,255,${alpha})`;
};

export const roundToNearestNumber = (value: number, roundToNumber: number) =>
  Math.ceil(value / roundToNumber) * roundToNumber;

export const mergeQueryStrings = (qsArray: string[]): string => {
  if (!qsArray.length) return "";
  return qsArray.join("&");
};

export const getQueryStringFromPaginationObj = (obj: IPagination): string => {
  let qs = "";
  if (isEmptyObject(obj)) return qs;
  const qsArray = Object.entries(obj).map(([key, value]) => `${key}=${value}`);
  qs = mergeQueryStrings(qsArray);
  return qs;
};

export const getOffsetFromPageAndPageSize = (page: number, pageSize: number) =>
  (page - 1) * pageSize;

export const appendTabToRouteQuery = (selectedTab: string) => {
  const queryParams = new URLSearchParams(window.location.search);
  queryParams.set("tab", selectedTab);
  const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
  window.history.replaceState(null, "", newUrl);
};

export const createTxtFile = (filename: string, content: string) => {
  const file = new File([content], `${filename}.txt`, { type: "text/plain" });
  return file;
};

export const formatLargeNumber = (number: number) => {
  if (number < 1000) {
    return number.toString(); // Numbers less than 1,000 are returned as-is.
  } else if (number < 1000000) {
    return (number / 1000).toFixed(1) + 'k'; // Thousands, one decimal place (e.g., 3.5k).
  } else if (number < 1000000000) {
    return (number / 1000000).toFixed(1) + 'M'; // Millions, one decimal place (e.g., 2.7M).
  } else {
    return (number / 1000000000).toFixed(1) + 'B'; // Billions, one decimal place (e.g., 1.2B).
  }
}


export const getTagColor = (message: string): string => {
  if (!message) return 'default';
  if (message.toLowerCase().includes('success')) return 'success';
  if (message.toLowerCase().includes('completed')) return 'success';
  if (message.toLowerCase().includes('failed')) return 'error';
  if (message.toLowerCase().includes('pending')) return 'processing';
  if (message.toLowerCase().includes('download')) return 'processing';
  if (message.toLowerCase().includes('in-progress')) return 'processing';
  return 'default'; // Default color if none of the conditions are met
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};