import { configureStore } from "@reduxjs/toolkit";
import { Store as ReduxStore } from "redux";
import { routerMiddleware } from "react-router-redux";
import { createBrowserHistory } from "history";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createReducer, { initialState } from "./reducers";
export * from "./actions";

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["authUser"],
};

export type Store = ReduxStore<typeof initialState>;
export const history: any = createBrowserHistory();
const store = (): Store => {
  const middlewares = [routerMiddleware(history)];
  const persistedReducer = persistReducer(persistConfig, createReducer());
  const customStore = configureStore({
    reducer: persistedReducer,
    middleware: middlewares,
    preloadedState: initialState,
    // devTools: import.meta.env.NODE_ENV !== "production",
  });
  return customStore;
};

const getState = store().getState;
export type RootState = ReturnType<typeof getState>;

export default store;
