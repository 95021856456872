import { combineReducers } from "redux";
import { quesAnsSlice, quesAnsInitialState } from "./question-answer/slice";

import uiReducer, { uiInitialState } from "./ui/slice";
import authUserReducer, { authUserInitialState } from "./auth-user/slice";
import userApprovalReducer, {
  userApprovalInitialState,
} from "./user-approval/slice";
import feedbackReducer, { feedbackInitialState } from "./feedback/slice";
import fileFolderUploadReducer, {
  fileFolderUploadInitialState,
} from "./file-folder-upload/slice";
import { documentInitialState, documentSlice } from "./actions";

export const initialState = {
  ui: uiInitialState,
  authUser: authUserInitialState,
  userApproval: userApprovalInitialState,
  feedback: feedbackInitialState,
  fileFolderUpload: fileFolderUploadInitialState,
  quesAns: quesAnsInitialState, // Add quesAns to the initialState object
  document: documentInitialState, 
};

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    ui: uiReducer,
    authUser: authUserReducer,
    userApproval: userApprovalReducer,
    feedback: feedbackReducer,
    fileFolderUpload: fileFolderUploadReducer,
    quesAns: quesAnsSlice.reducer,
    document: documentSlice.reducer,
    ...injectedReducers,
  });

  return rootReducer;
}
